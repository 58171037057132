import React from "react"
import styled from "styled-components"

export const StyledButton = styled.button`
  margin-top: 5rem;
  grid-column: 1 / span 1;
  grid-row: 2;
  background-color: ${props => props.theme.colors.cards};
  height: 5.3em;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0 0 52px 0;
  cursor: pointer;
  :hover  {
    background-color: #111112;
  }
  @media screen and (max-width: 90rem) {
    margin-top: 1rem;
    grid-column: 2 / span 1;
    height: 4.3em;
    width: 100%;
    background-color: ${props => props.theme.colors.darkHighlight};
    border-color: ${props => props.theme.colors.darkHighlight};
    border-radius: 0 0 42px 0;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 2;
    height: 3.3em;
    width: 100%;
    border-radius: 0 0 32px 0;
  }
`
export const Arrow = styled.div`
  margin-left: 6vw;
  transform: rotate(-90deg);
  width: 1.6em;
  @media screen and (max-width: 90rem) {
    margin-left: 4.5vw;
  }
  @media screen and (max-width: 37rem) {
    margin-left: 7vw;
  }
`

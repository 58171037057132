import React from "react"
import Layout from "../components/Layout"
import { graphql, Link, navigate } from "gatsby"
import Seo from "../components/Seo"
import styled from "styled-components"
import { Arrow, StyledButton } from "../components/Back"
import ArrowUp from "../icons/arrow-up.svg"
import { SubHeader } from "../components/Subheader"

import TextHighlight from "../components/TextHighlight"
import Space from "../components/Space"
import { SiteGoal } from "../components/HeaderSection"
import {
  Card, CardContent, CardDescription, CardRole, CardTitle,
  FirstRole,
  RoleDescription,
  RoleHeadline,
  SecondRole
} from "../components/BestPracticeStyled"
import { Terminologies } from "../components/Terminologies"
import { MindsetPT, MindsetPTBackground } from "../pages"
import {
  CATSubheadline,
  CATUppercase,
  PTButton,
} from "../components/CTASection"
import Button from "../components/Button"
import Image from "../img/best-practices/low-energy-colors.svg"
import resources from "../resources/resources.json"
import CardBackground from "../components/CardBackground"
import NewBadge, { BadgeSection, NewBadgeText } from "../components/NewBadge"
import {
  Backlink, BackLinkSection,
  ResourceBottomRow,
  ResourceCard,
  ResourceCardCategory,
  ResourceCardDescription, ResourceCardLink,
  ResourceCardTitle, ResourceMobileLink
} from "../pages/resources"
import practices from "../hints/data.json"
import { BackArrow, StyledBackButton } from "../components/BackSideEnd"

const HeadingH1 = styled.h1`
  font-size: 5em;
  font-weight: 700;

  @media screen and (max-width: 90rem) {
    font-size: 3.5em;
  }
  @media screen and (max-width: 37rem) {
    font-size: 2.5em;
  }
`
const Roles = styled.p`
  font-size: 1em;
  color: ${props => props.theme.colors.secundaryDark};
  text-transform: uppercase;
  text-decoration: underline;
  margin-bottom: 80px;
`
const Pair = styled.ul`
  list-style-type: none;
`
const Description = styled.p`
  font-size: 1.5em;
  font-weight: normal;
  color: ${props => props.theme.colors.secundaryDark};
`
const HeroImage = styled.image`
  grid-column: 2 / span 2;
  grid-row: 2 /3;
  display: flex;
  margin: 80px 0;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 2;
    grid-row: 2 /3;
  }
`
export const PrincipleDescriptionSection = styled.div`
  position: relative;
  grid-column: 2 / span 4;
  grid-row: 8;
  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 3;
    margin-top: 25px;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 6;
    margin-top: 20px;
    text-align: left;
  }
`
export const PrincipleDescription = styled.h4`
  font-size: 1.5em;
  position: absolute;
  bottom: 0;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: right;
  color: ${props => props.theme.colors.tertiary};
  text-transform: uppercase;

  @media screen and (max-width: 90rem) {
    position: relative;
    margin-top: 0;
    font-size: 1.1em;
    margin-bottom: 10px;
    text-align: left;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 6;
    font-size: 1em;
    text-align: left;
  }
`
const TermConclusion = styled.h4`
  grid-column: 6 / span 8;
  grid-row: 9;
  padding-top: 0;
  text-align: right;
  @media screen and (max-width: 90rem) {
    grid-column: 5 / span 3;
    margin-top: 25px;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 6;
    grid-row: 11;
    margin-top: 0;
    text-align: left;
  }
`

export const ExplainSectionBackground = styled.div`
  z-index: -1;
  grid-column: 6 / span 9;
  grid-row: 11;
  background-color: ${props => props.theme.colors.shapes};
  height: auto;
  border-radius: 280px 0 0 0;
  @media screen and (max-width: 90rem) {
    grid-column: 4 / span 8;
    height: auto;
    border-radius: 200px 0 0 0;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 4 / span 5;
    height: auto;
    border-radius: 125px 0 0 0;
  }
  @media screen and (max-width: 37rem) {
    grid-row: 13;
  }
`
export const ExplainSection = styled.div`
  grid-column: 4 / span 8;
  grid-row: 11;

  @media screen and (max-width: 90rem) {
    grid-column: 3 / span 4;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 5;
    grid-row: 13;
  }
`

export const HeadlineSection = styled.div`
  display: inline-grid;
  grid-template-columns: 3fr 1fr;
`
export const Headline = styled.h3`
  padding-top: 11rem;
  vertical-align: middle;
`

const TextSection = styled.div`
  padding: 2rem 0 28rem 0;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.4vw;

  @media screen and (max-width: 90rem) {
    padding: 2rem 0 20rem 0;
  }
  @media screen and (max-width: 63rem) {
    grid-template-columns: 1fr;
    grid-row-gap: 5vw;
    padding: 2rem 0 12rem 0;
  }
  @media screen and (max-width: 37rem) {
    padding: 2rem 0 8rem 0;
  }
`
const ImageSection = styled.div`
  margin-top: -25rem;
  grid-column: 2 / span 12;
  grid-row: 12;
  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
    grid-row: 14;
    margin-top: -19rem;
  }
  @media screen and (max-width: 63rem) {
    display: none;
  }
`
const KeyActionHeadline = styled.h3`
  grid-column: 3 / span 11;
  grid-row: 14;
  text-align: center;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
    grid-row: 15;
  }
`
const KeyActionSection = styled.div`
  grid-column: 2 / span 11;
  grid-row: 15;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
    grid-row: 16;
  }
`

const KeyAction = styled.div`
  padding-top: 2rem;
  display: flex;
  align-items: baseline;
`
const Number = styled.h3`
  padding-right: 2rem;
  float: left;
`
const MatchingResourcesHeadline = styled.div`
  margin-bottom: 1.4vw;
  grid-column: 2 / span 12;
  grid-row: 17;

  @media screen and (max-width: 90rem) {
    margin-bottom: 2vw;
    grid-column: 2 / span 6;
    grid-row: 18;
  }

  @media screen and (max-width: 37rem) {
    margin-bottom: 6vw;
  }
`
const MatchingPracticesHeadline = styled.div`
  margin-bottom: 1.4vw;
  grid-column: 2 / span 12;
  grid-row: 20;

  @media screen and (max-width: 90rem) {
    margin-bottom: 2vw;
    grid-column: 2 / span 6;
    grid-row: 21;
  }

  @media screen and (max-width: 37rem) {
    margin-bottom: 6vw;
  }
`
const MatchingResourceSection = styled.section`
  display: grid;
  grid-row: 18;
  grid-column-start: 2;
  grid-column-end: 14;
  grid-template-columns: 77.2vw;
  grid-template-rows: auto;
  grid-gap: 1.4vw 1.4vw;

  @media screen and (max-width: 90rem) {
    display: grid;
    grid-row: 19;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-template-columns: 88vw;
    grid-template-rows: auto;
    grid-gap: 2vw 2vw;
  }
  @media screen and (max-width: 37rem) {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-template-columns: 93vw;
    grid-template-rows: auto;
    grid-gap: 6vw;
  }
`
const MatchingPracticeSection = styled.div`
  z-index: 1;
  display: grid;
  grid-row: 21;
  grid-column-start: 2;
  grid-column-end: 14;
  grid-template-columns: 24.8vw 24.8vw 24.8vw;
  grid-template-rows: auto;
  grid-gap: 1.4vw 1.4vw;

  @media screen and (max-width: 90rem) {
    display: grid;
    grid-row: 22;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-template-columns: 28vw 28vw 28vw;
    grid-template-rows: auto;
    grid-gap: 2vw 2vw;
  }

  @media screen and (max-width: 58rem) {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-template-columns: 43vw 43vw;
    grid-template-rows: auto;
    grid-gap: 2vw 2vw;
  }
  @media screen and (max-width: 46rem) {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-template-columns: 88vw;
    grid-template-rows: auto;
    grid-gap: 6vw;
  }
  @media screen and (max-width: 37rem) {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-template-columns: 93vw;
    grid-template-rows: auto;
    grid-gap: 6vw;
  }
`

export const query = graphql`
  query($slug: String!) {
    dataJson(slug: { eq: $slug }) {
      color
      title
      seoTitle
      seoDescription
      seoKeywords
      titleHighlight
      secondRow
      thirdRow
      subTitle
      siteGoal
      terms
      termDescription
      firstTermTitle
      ftSectionOne
      ftSectionTwo
      firstTermSource
      secondTermTitle
      stSectionOne
      stSectionTwo
      secondTermSource
      termConclusion
      explainHighlight
      explainHeadline
      explainSectionOne
      explainSectionTwo
      explainSectionThree
      explainSectionFour
      description
      image
      alt
      shortForm
      actionHeadline
      first
      firstKeyAction
      second
      secondKeyAction
      third
      thirdKeyAction
      fourth
      fourthKeyAction
      role
    }
  }
`
export default function HintDetail({ data }) {
  let resourceCards = []

  resources.forEach(element => {
    if (element.matching.includes(data.dataJson.title))  {
      resourceCards.push(
        <a
          href={element.slug}
          title={element.title}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CardBackground colorValue={element.color}>
            <ResourceCard>
              <BadgeSection>
                {Date.now() - new Date(element.timeStamp).getTime() <
                2628002880 && (
                  <NewBadge colorValue={element.color}>
                    <NewBadgeText>New</NewBadgeText>
                  </NewBadge>
                )}
              </BadgeSection>
              <ResourceCardTitle>
                {element.title}&nbsp;
                <TextHighlight colorValue={"grey"}>
                  @{element.creator}
                </TextHighlight>
              </ResourceCardTitle>
              <ResourceCardDescription>{element.description}</ResourceCardDescription>
              <ResourceBottomRow>
                <ResourceCardCategory>
                  <TextHighlight colorValue={element.color}>
                    {element.category}
                  </TextHighlight>
                </ResourceCardCategory>
                <ResourceCardLink>
                  <TextHighlight grey>{element.link}</TextHighlight>
                </ResourceCardLink>
              </ResourceBottomRow>
              <ResourceMobileLink>
                <TextHighlight colorValue={"grey"}>
                  {element.link}
                </TextHighlight>
              </ResourceMobileLink>
            </ResourceCard>
          </CardBackground>
        </a>
      )
    }
  })

  let cards = []
  practices.forEach(element => {
    if (
      element.role.includes(data.dataJson.role) && !element.title.includes(data.dataJson.title)) {
      cards.push(
        <Link to={"/best-practices/" + element.slug}>
          <CardBackground colorValue={element.color}>
            <Card>
              <BadgeSection>
                {Date.now() - new Date(element.timeStamp).getTime() <
                2628002880 ? (
                  <NewBadge colorValue={element.color}>
                    <NewBadgeText>New</NewBadgeText>
                  </NewBadge>
                ) : (
                  <NewBadge colorValue={"hidden"}>
                    <NewBadgeText>New</NewBadgeText>
                  </NewBadge>
                )}
              </BadgeSection>
              <CardContent>
                <CardTitle>{element.title}</CardTitle>
                <CardDescription>{element.description}</CardDescription>
                <CardRole>
                  <TextHighlight colorValue={element.color}>
                    {element.role}
                  </TextHighlight>
                </CardRole>
              </CardContent>
            </Card>
          </CardBackground>
        </Link>
      )
    }
  })

  return (
    <Layout>
      <Seo
        title={data.dataJson.seoTitle}
        description={data.dataJson.seoDescription}
        keywords={data.dataJson.seoKeywords}
      />
      <StyledButton onClick={() => navigate(-1)}>
        <Arrow>
          <img src={ArrowUp} alt={"Arrow up"} />
        </Arrow>
      </StyledButton>
      <SubHeader>
        <div>
          <h1>
            <TextHighlight colorValue={data.dataJson.color}>
              {data.dataJson.titleHighlight}
            </TextHighlight>
            <br />
            {data.dataJson.secondRow}
            <br />
            {data.dataJson.thirdRow}
          </h1>
          <h2>
            <TextHighlight colorValue={data.dataJson.color}>
              {data.dataJson.subTitle}
            </TextHighlight>
          </h2>
        </div>
      </SubHeader>
      <Space big four />
      <SiteGoal>
        <h2>{data.dataJson.siteGoal}</h2>
      </SiteGoal>
      <RoleHeadline>{data.dataJson.terms}</RoleHeadline>
      <PrincipleDescriptionSection>
        <PrincipleDescription>
          {data.dataJson.termDescription}
        </PrincipleDescription>
      </PrincipleDescriptionSection>
      <FirstRole>
        <Terminologies colorValue={data.dataJson.color}>
          {data.dataJson.firstTermTitle}
        </Terminologies>
        <p>
          {data.dataJson.ftSectionOne}
          <br /> <br />
          {data.dataJson.ftSectionTwo}{" "}
          <Link to="/resources#sources">
          <TextHighlight colorValue={"smallSecundaryDark"}>
            {data.dataJson.firstTermSource}
          </TextHighlight></Link>
        </p>
      </FirstRole>
      <SecondRole>
        <Terminologies colorValue={data.dataJson.color}>
          {data.dataJson.secondTermTitle}
        </Terminologies>
        <p>
          {data.dataJson.stSectionOne}
          <br /> <br />
          {data.dataJson.stSectionTwo}{" "}<Link to="/resources#sources">
          <TextHighlight colorValue={"smallSecundaryDark"}>
            {data.dataJson.secondTermSource}
          </TextHighlight></Link>
        </p>
      </SecondRole>
      <TermConclusion>
        <TextHighlight colorValue={data.dataJson.color}>
          {data.dataJson.termConclusion}
        </TextHighlight>
      </TermConclusion>
      <Space big hdTen />
      <ExplainSectionBackground />
      <ExplainSection>
        <HeadlineSection>
          <Headline>
            <TextHighlight colorValue={data.dataJson.color}>
              {data.dataJson.explainHighlight}
            </TextHighlight>{" "}
            {data.dataJson.explainHeadline}
          </Headline>
        </HeadlineSection>
        <TextSection>
          <p>
            {data.dataJson.explainSectionOne}
            <br /> <br />
            {data.dataJson.explainSectionTwo}
          </p>
          <p>
            {data.dataJson.explainSectionThree}
            <br /> <br />
            {data.dataJson.explainSectionFour}
          </p>
        </TextSection>
      </ExplainSection>
      {!data.dataJson.image.includes("false") && <ImageSection>
        <img src={data.dataJson.image} alt={data.dataJson.alt} />
      </ImageSection>}

      <Space big hdThirteen />
        <KeyActionHeadline>
          {data.dataJson.actionHeadline}{" "}
          <TextHighlight colorValue={data.dataJson.color}>
            {data.dataJson.shortForm}
          </TextHighlight>
        </KeyActionHeadline>
      <KeyActionSection>
        <KeyAction>
          <Number>
            <TextHighlight colorValue={data.dataJson.color}>
              {data.dataJson.first}
            </TextHighlight>
          </Number>
          <h2>{data.dataJson.firstKeyAction}</h2>
        </KeyAction>
        <KeyAction>
          <Number>
            <TextHighlight colorValue={data.dataJson.color}>
              {data.dataJson.second}
            </TextHighlight>
          </Number>
          <h2>{data.dataJson.secondKeyAction}</h2>
        </KeyAction>
        <KeyAction>
          <Number>
            <TextHighlight colorValue={data.dataJson.color}>
              {data.dataJson.third}
            </TextHighlight>
          </Number>
          <h2>{data.dataJson.thirdKeyAction}</h2>
        </KeyAction>
        <KeyAction>
          <Number>
            <TextHighlight colorValue={data.dataJson.color}>
              {data.dataJson.fourth}
            </TextHighlight>
          </Number>
          <h2>{data.dataJson.fourthKeyAction}</h2>
        </KeyAction>
      </KeyActionSection>
      <Space big sixteen />
      <MatchingResourcesHeadline>
        {resourceCards.length > 0  && <h3>Matching resources</h3>}
      </MatchingResourcesHeadline>
      <MatchingResourceSection>{resourceCards}</MatchingResourceSection>
      {resourceCards.length > 0 && <Space big dhNineteen />}
      <MatchingPracticesHeadline>
        {cards.length > 0 && <h3>Related practices</h3>}
      </MatchingPracticesHeadline>
      <MatchingPracticeSection>{cards}</MatchingPracticeSection>
      {cards.length > 0 && <Space big hdTwentytwo />}
      <StyledBackButton onClick={() => navigate("/best-practices")}>
        <BackArrow>
          <img src={ArrowUp} alt={"Arrow up"} />
        </BackArrow>
      </StyledBackButton>
      <BackLinkSection onClick={() => navigate("/best-practices")}>
        <Backlink>View all Practices</Backlink>
      </BackLinkSection>
    </Layout>
  )
}

import styled from "styled-components"

export const SubHeader = styled.section`
  grid-column: 8 / span 6;
  grid-row: 3;
  justify-self: end;
  text-align: right;

  @media screen and (max-width: 90rem) {
    grid-column: 5 / span 3;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
  }
`